import { CognitoJwtVerifier } from "aws-jwt-verify/cognito-verifier";
import config from "./config.js";
import { signOut } from "aws-amplify/auth";

class Authentication {
  constructor() {
    this.loginUrl = `${config.LOGIN_URL}/login?client_id=3jcm2o6c4cikqsh8e31a22ockt&response_type=token&scope=email+openid+profile&redirect_uri=${window.location.protocol}//${window.location.host}`;
  }

  async login() {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessTokenFromQuery = urlParams.get("access_token");
    const idTokenFromQuery = urlParams.get("id_token");
    const refreshTokenFromQuery = urlParams.get("refresh_token");

    if (accessTokenFromQuery && idTokenFromQuery) {
      this.clearSessionStorage();
      try {
        const accessTokenVerifier = this.createVerifier("access");
        const idTokenVerifier = this.createVerifier("id");
        await accessTokenVerifier.verify(accessTokenFromQuery);
        const idTokenPayload = await idTokenVerifier.verify(idTokenFromQuery);

        this.storeTokens(idTokenFromQuery, accessTokenFromQuery, refreshTokenFromQuery);
        localStorage.setItem("isAuthenticated", "true");

        setTimeout(() => {
          window.location.href = `${window.location.protocol}//${window.location.host}`;
        }, 1000);
        return idTokenPayload;
      } catch (error) {
        console.error("Error verifying tokens from query string:", error);
        throw error;
      }
    } else {
      try {
        const accessToken = sessionStorage.getItem("accessToken");
        if (accessToken) {
          const accessTokenVerifier = this.createVerifier("access");
          await accessTokenVerifier.verify(accessToken);

          const idToken = sessionStorage.getItem("idToken");
          const idTokenVerifier = this.createVerifier("id");
          const idTokenPayload = await idTokenVerifier.verify(idToken);

          return idTokenPayload;
        } else {
          throw new Error("No valid access token in session storage");
        }
      } catch (error) {
        console.error("Access token is invalid or missing:", error);
        return null;
      }
    }
  }
  
  async logout() {
    try {
      await signOut({ global: true });
      this.clearSessionStorage();
      localStorage.removeItem("isAuthenticated");
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  }
  
  async isAdmin() {
    try {
      const idToken = sessionStorage.getItem("idToken");
      if (idToken) {
        const idTokenVerifier = this.createVerifier("id");
        const idTokenPayload = await idTokenVerifier.verify(idToken);
        return idTokenPayload["cognito:groups"]?.includes("Download-Portal-Admins") ?? false;
      }
      return false;
    } catch (error) {
      console.error("Error checking admin status:", error);
      throw error;
    }
  }

  createVerifier(tokenUse) {
    return CognitoJwtVerifier.create({
      userPoolId: config.aws_amplify.Auth.UserPoolId,
      tokenUse,
      clientId: config.aws_amplify.Auth.UserPoolWebClientId,
    });
  }

  storeTokens(idToken, accessToken, refreshToken) {
    sessionStorage.setItem("idToken", idToken);
    sessionStorage.setItem("accessToken", accessToken);
    if (refreshToken) {
      sessionStorage.setItem("refreshToken", refreshToken);
    }
  }

   clearSessionStorage() {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }
}

export default Authentication;