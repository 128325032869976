import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bulma/css/bulma.min.css';
import './index.css';
import './components/style.css';
import 'react-tooltip/dist/react-tooltip.css'
import App from './App';
import { Amplify } from 'aws-amplify';
import config from "./config.js";
import * as serviceWorker from './serviceWorker';
/* eslint-disable no-unused-vars */
import back from './img/back-button.png';
import del from './img/delete.png';
import edit from './img/edit.png';
import cover from './img/image-cloud-services2.jpg';
import loginicon from './img/login-icon.png';
import customx from './img/customx-Logo.png';
import exs from './img/exs-Logo.png';
import mapedit from './img/mapedit-Logo.png';
import ginfo from './img/ginfo-Logo.png';
import acad from './img/mumautocadbooster-Logo.png';
import bim from './img/mumbimbooster-Logo.png';
import mbrowser from './img/mummaterialbrowserforinventor-Logo.png';
import mtool from './img/mummultitoolforinventor-Logo.png';
import pdm from './img/mumpdmbooster-Logo.png';
import stahlbau from './img/mumpraxispaketstahlbau-Logo.png';
import qto from './img/mumqtobooster-Logo.png';
import swork from './img/mumsteelworkforinventor-Logo.png';
/* eslint-enable no-unused-vars */

const amplifyConfig = {
  Auth: {
    Cognito: {
      mandatorySignIn: true,
      region: config.aws_amplify.Auth.Region,
      userPoolId: config.aws_amplify.Auth.UserPoolId,
      userPoolClientId: config.aws_amplify.Auth.UserPoolWebClientId,
      identityPoolId: config.aws_amplify.Auth.IdentityPoolId,
    }
  }
};

try {
  Amplify.configure(amplifyConfig);
} catch (error) {
  console.error("Error configuring Amplify:", error);
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();