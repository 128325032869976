import React, { Component, Fragment } from "react";
import { S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Upload } from "@aws-sdk/lib-storage";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config.js";
import Authentication from "../Authentication";
import { ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default class ProductAdmin extends Component {

  state = {
    newproduct: {
      software: "",
      year: "",
      language: "",
      category: "",
      fullname: "",
      version: "",
      descriptionothers: "",
      md5hash: "",
      releaseNotes: "",
    },
    uploadedfile: null,
    products: [],
    showModal: false,
    fullnameError: "",
    successMessage: "",
    uploadProgress: 0,
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    this.setState({ uploadedfile: file });
  };

  handleAddProduct = async (event) => {
    event.preventDefault();
    const auth = new Authentication();
    try {
      await auth.login();
    } catch (err) {
      console.error("User not authenticated:", err);
      return;
    }
  
    const id = this.state.uploadedfile ? this.state.uploadedfile.name : 'file';
    try {
        const credentials = fromCognitoIdentityPool({
          identityPoolId: config.aws_amplify.Auth.IdentityPoolId,
          logins: {
            [`cognito-idp.${config.region}.amazonaws.com/${config.aws_amplify.Auth.UserPoolId}`]: sessionStorage.getItem('idToken')
          },
          clientConfig: { region: config.region },
        });
  
      const s3Client = new S3Client({
        region: config.region,
        credentials,
      });
  
      if (this.state.uploadedfile) {
        const key = `${id}`;
        const params = {
          Bucket: config.S3BucketName,
          Key: key,
          Body: this.state.uploadedfile,
          ACL: "bucket-owner-full-control",
        };
        const upload = new Upload({
          client: s3Client,
          params,
          leavePartsOnError: false,
        });
        upload.on("httpUploadProgress", (progress) => {
          const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
          this.setState({ uploadProgress: progressPercentage });
        });
  
        try {
          await upload.done();
          const fileLocation = `https://${config.S3BucketName}.s3.${config.region}.amazonaws.com/${key}`;
          this.addProductWithFile(id, fileLocation, this.state.uploadedfile.size);
        } catch (error) {
          console.error("S3 upload error:", error);
          window.alert("Error uploading new file:" + error.message);
        }
      } else {
        this.addProductWithFile(id, null, null);
      }
    } catch (error) {
      console.error("Error in handleAddProduct:", error);
      console.error("Error name:", error.name);
      console.error("Error message:", error.message);
  
      if (error.name === "CredentialsError") {
        console.error("CredentialsError detected. AWS credentials may be invalid or expired.");
      }
    }
  };  

  addProductWithFile = async (id, fileLocation, fileSize) => {
    const {
      software,
      year,
      language,
      category,
      fullname,
      version,
      descriptionothers, 
      md5hash,
      releaseNotes,
    } = this.state.newproduct;
    const uploadDate = new Date().toISOString();
    const params = {
      id: id,
      download: fileLocation,
      uploadDate: uploadDate,
      fileSize: fileSize,
      fullname: fullname,
      releaseNotes: releaseNotes,
    };

    if (software) params.software = software;
    if (year) params.year = year;
    if (language) params.language = language;
    if (category) params.category = category;
    if (version) params.version = version;
    if (fullname) params.fullname = fullname;
    if (descriptionothers) params.descriptionothers = descriptionothers;
    if (md5hash) params.md5hash = md5hash;

    try {
      const idToken = sessionStorage.getItem("idToken");
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };

      await axios.post(`${config.api.invokeUrl}/Products/${id}`, params, {
        headers,
      });
      this.setState({ products: [...this.state.products, params], successMessage: "Product successfully uploaded.", showModal: true, });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      this.setState({
        newproduct: {
          software: "",
          year: "",
          language: "",
          category: "",
          fullname: "",
          version: "",
          descriptionothers: "",
          md5hash: "",
          releaseNotes: "",
        },
        uploadedfile: null,
      });
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      console.error('Error details:', err.response ? err.response.data : err);
    }
  };

fetchProducts = async () => {
  const auth = new Authentication();
  try {
    const isAuthenticated = this.props.auth.isAuthenticated;

    if (!isAuthenticated) {
    } else {
      const currentUser = await auth.login();
      if (!currentUser) {
        throw new Error("Failed to authenticate user");
      }
      const idToken = sessionStorage.getItem("idToken");

      const headers = {
        Authorization: `Bearer ${idToken}`,
      };

      const url = `${config.api.invokeUrl}/Products`;
      const res = await axios.get(url, { headers });
      const products = res.data;

      this.setState({ products }); 
    }
  } catch (err) {
    console.log(`An error has occurred: ${err}`);
    }
};
  onAddProductSoftwareChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, software: event.target.value },
    });
  onAddProductYearChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, year: event.target.value },
    });
  onAddProductLanguageChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, language: event.target.value },
    });  
  onAddProductCategoryChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, category: event.target.value },
    });
  onAddProductFullnameChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, fullname: event.target.value },
    });
  onAddProductVersionChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, version: event.target.value },
    });
  onAddProductDescriptionothersChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, descriptionothers: event.target.value },
    });  
  onAddProductMd5HashChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, md5hash: event.target.value },
    });
  onAddProductReleaseNotesChange = (event) =>
    this.setState({
      newproduct: { ...this.state.newproduct, releaseNotes: event },
    });

  componentDidMount = () => {
    this.fetchProducts();
  };

  renderYearSelectOptions = () => {
    const { newproduct } = this.state;
    const { software } = newproduct;

    if (software === "mumqtobooster") {
      return (
        <>
          <option value="" disabled>
            Select a year
          </option>
          <option value="V8">V8</option>
          <option value="V7">V7</option>
          <option value="V6">V6</option>
        </>
      );
    } else {
      return (
        <>
          <option value="" disabled>
            Select a year
          </option>
          <option value="2025">2025</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
        </>
      );
    }
  };

  render() {
    const { showModal, successMessage, uploadProgress, fullnameError } = this.state;
    return (
      <Fragment>
        {showModal && (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="box">
                <p>{successMessage}</p>
              </div>
            </div>
            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={() => this.setState({ showModal: false })}
            ></button>
          </div>
        )}
        <section className="section" style={{paddingBottom: '160px', position: 'relative'}}>
          <Link to="/" className="button-back" style={{ justifyContent: 'flex-end', left: "10px", position: 'absolute' }}>
          <img src={require("../img/back-button.png")} alt="Back" />
          </Link>
          <div className="container">
            <h1>Product Admin</h1>
            <br /> 
            <div className="columns">
              <div className="column full">
                <form onSubmit={this.handleAddProduct}>
                    <div className="field">
                    <br />
                    <label className="label">Software</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={this.state.newproduct.software}
                          onChange={(e) => this.onAddProductSoftwareChange(e)}
                        >
                          <option value="" disabled>
                            Select a software
                          </option>
                          <option value="customx">customX</option>
                          <option value="exs">eXs</option>
                          <option value="ginfo">G-Info NET Server</option>
                          <option value="mapedit">MapEdit</option>
                          <option value="mumautocadbooster">
                            MuM ACAD Toolbox
                          </option>
                          <option value="mumbimbooster">MuM BIM Booster</option>
                          <option value="mummaterialbrowserforinventor">
                            MuM Material Browser for Inventor
                          </option>
                          <option value="mummultitoolforinventor">
                            MuM Multitool for Inventor
                          </option>
                          <option value="mumpdmbooster">MuM PDM Booster</option>
                          <option value="mumpraxispaketstahlbau">
                            MuM Praxispaket Stahlbau
                          </option>
                          <option value="mumqtobooster">MuM QTO Booster</option>
                          
                          <option value="mumsteelworkforinventor">
                            MuM Steelwork for Inventor
                          </option>
                          
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Year</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={this.state.newproduct.year}
                          onChange={(e) => this.onAddProductYearChange(e)}
                        >
                          {this.renderYearSelectOptions()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <p className="subtitle is-fullwidth">
                      <strong>If you select "General download section" from "Language" list and select a category from "Category" list, then the downloadable file will be shown only in the General download section inside the tile. If you select any "Quick download" from "Language" list and select a category from "Category" list, then the file will be shown in the Quick download tile and in the General download section.</strong>
                    </p>
                    <label className="label">Language</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={this.state.newproduct.language}
                          onChange={(e) => this.onAddProductLanguageChange(e)}
                        >
                          <option value="">
                            General download section
                          </option>
                          <option value="English">Quick download - English</option>
                          <option value="Deutsch">Quick download - Deutsch</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Category</label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          value={this.state.newproduct.category}
                          onChange={(e) => this.onAddProductCategoryChange(e)}
                        >
                          <option value="" disabled>
                            Select a category
                          </option>
                          <option value="Release">Release</option>
                          <option value="Updates">Updates</option>
                          <option value="AddOn">AddOn</option>
                          <option value="Documentation">Documentation</option>
                          <option value="Content">Content (Only for Bim Booster)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Fullname</label>
                    <div className="control">
                      <input
                        className="input is-medium"
                        type="text"
                        value={this.state.newproduct.fullname}
                        onChange={this.onAddProductFullnameChange}
                      />
                      {fullnameError && (
                        <p className="help is-danger">{fullnameError}</p>
                      )}
                    </div>
                  </div>                 
                  <div className="field">
                    <label className="label">Version</label>
                    <div className="control">
                      <input
                        className="input is-medium"
                        type="text"
                        value={this.state.newproduct.version}
                        onChange={this.onAddProductVersionChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Description</label>
                    <div className="control">
                      <input
                        className="input is-medium"
                        type="text"
                        value={this.state.newproduct.descriptionothers}
                        onChange={this.onAddProductDescriptionothersChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">MD5Hash</label>
                    <div className="control">
                      <input
                        className="input is-medium"
                        type="text"
                        value={this.state.newproduct.md5hash}
                        onChange={this.onAddProductMd5HashChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Release Notes</label>
                    <div className="control">
                      <ReactQuill 
                        theme="snow"
                        value={this.state.newproduct.releaseNotes}
                        onChange={this.onAddProductReleaseNotesChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Upload File</label>
                    <div className="control">
                      <input
                        className="input is-medium"
                        type="file"
                        accept="*/*"
                        onChange={this.handleFileUpload}
                      />
                    </div>
                  </div>
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-primary is-medium"
                    >
                      Add product
                    </button>
                  </div>
                </form>
                {uploadProgress > 0 && (
                  <div className="mt-3">
                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
      
    );
  }
}
