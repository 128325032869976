import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Products from "./components/Products";
import ProductAdmin from "./components/ProductAdmin";
import RequireAuth from "./components/RequireAuth";
import config from "./config.js";
import Error from "./components/error";
import Authentication from "./Authentication";
import axios from "axios";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: false,
    user: null,
    isAdmin: false,
    userLicenses: [],
    error: null,
  };

  async componentDidMount() {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    if (isAuthenticated) {
      this.setState({ isAuthenticating: true });
      await this.checkAuthStatus();
    }
  }

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  setUserLicenses = (licenses) => {
    this.setState({ userLicenses: licenses });
  };
  setError = (error) => {
    this.setState({ error });
  };

  checkAuthStatus = async () => {
    const auth = new Authentication();
    try {
      const idTokenPayload = await auth.login();
      if (idTokenPayload) {
        this.setAuthStatus(true);
        this.setUser(idTokenPayload);

        const email = idTokenPayload.email;
        const session = sessionStorage.getItem("idToken");

        if (session) {
          try {
            const response = await axios.get(
              `${config.api.invokeUrl}/GetAdminInfo?email=${email}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${session}`,
                },
              }
            );

            if (response.status === 200) {
              const isAdmin = response.data.isAdmin;
              this.setState({ isAdmin });

              await this.fetchUserLicenses(email, session);
            } else {
              console.error("Error fetching user data:", response.statusText);
              this.setError(`Error fetching user data: ${response.statusText}`);
            }
          } catch (sessionError) {
            console.error('Error fetching session:', sessionError);
            this.setError('Error fetching session');
          }
        }
      } else {
        this.setAuthStatus(false);
        this.setUser(null);
      }
    } catch (error) {
      console.error('Error:', error);
      this.setError('Error checking user session');
    } finally {
      this.setState({ isAuthenticating: false });
    }
  };

  handleLogin = async () => {
    this.setState({ isAuthenticating: true });
    await this.checkAuthStatus();
  };

  handleLogout = async () => {
    const auth = new Authentication();
    await auth.logout();
    this.setAuthStatus(false);
    this.setUser(null);
    this.setState({ isAdmin: false });
    this.setUserLicenses(["Download Portal General Access"]);
  };
  
  fetchUserLicenses = async (email, session) => {
  
    try {
      const response = await axios.get(`${config.mumapapi.invokeUrl}/UserLicenses?email=${encodeURIComponent(email)}`, {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      });
  
      if (response.status === 200) {
        const { licenses } = response.data;
        this.setState({ userLicenses: licenses });
      } else {
        console.error("Error fetching user licenses:", response.statusText);
        this.setError(`Error fetching user licenses: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching user licenses:', error);
      this.setError('Error fetching user licenses');
    }
  };
  
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      isAdmin: this.state.isAdmin,
      userLicenses: this.state.userLicenses,
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router>
            <RequireAuth setUserLicenses={this.setUserLicenses}>
              <div>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => (
                      <Products {...props} auth={authProps} handleLogin={this.handleLogin} handleLogout={this.handleLogout}/>
                    )}
                  />
                  <Route
                    exact
                    path="/admin"
                    render={(props) => {
                      if (authProps.isAuthenticated && authProps.isAdmin) {
                        return <ProductAdmin {...props} auth={authProps} />;
                      } else {
                        return <Redirect to="/" />;
                      }
                    }}
                  />
                  <Route
                    path="/*"
                    render={(props) => <Error {...props} auth={authProps} />}
                  />        
                </Switch>
                <Footer />
              </div>
            </RequireAuth>
          </Router>
        </div>
      )
    );
  }
}

export default App;
