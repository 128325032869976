const config = {
  api: {
    invokeUrl: process.env.REACT_APP_API_INVOKE_URL
  },
  mumapapi: {
    invokeUrl: process.env.REACT_APP_MUMAP_API_INVOKE_URL
  },
  aws_amplify: {
    Auth: {
      Region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
      UserPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
      UserPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
      IdentityPoolId: process.env.REACT_APP_AWS_AMPLIFY_IDENTITY_POOL_ID
    }
  },
  S3BucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  LicenseName: process.env.REACT_APP_LICENSE_NAME,
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL
};

export default config;
